<script>

import appConfig from "@/app.config";
import helper from "@/assets/helpers/helper";
import AddUniversity from "../../components/popups/addUniversity";

/**
 * Contacts-grid component
 */
export default {
  page: {
    title: "Universities",
    meta: [{name: "description", content: appConfig.description}]
  },
  name: 'Universities',
  components: {AddUniversity},
  data() {
    return {
      universities: [],
      title: "Universities",
      load: false
    };
  },
  created() {
    this.loadUniversities()
  },
  methods: {
    async loadUniversities() {
      this.universities = await helper.getAll('universities');
      this.load = true
    },

  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{ $t('universities.title') }}</h4>
        </div>
      </div>
      <div class="col-6 py-2 text-right">
        <a class="btn btn-primary" role="button" v-b-modal.add-university>{{ $t('profil_user.settings.add_uni') }}</a>
        <add-university/>
      </div>
    </div>

    <div v-if="load">
      <div class="row" v-if="universities.length">
        <div v-for="u in universities" :key="u.id" class="col-xl-3 col-sm-6 mb-4">
          <div class="card text-center h-100 ">
            <div class="card-body">
              <div class="mb-4">
                <b-avatar :src="u.logo" button square size="7em" :alt="u.name"
                          @click="$router.push({name:'ProfilUniversity',params:{id:u.id}})"></b-avatar>
              </div>

              <router-link class="h5 text-dark font-size-15 link" :to="{name:'ProfilUniversity',params:{id:u.id}}">
                {{ u.name }}
              </router-link>

              <p>{{ u.description }}</p>

              <div class="group-details  pb-3 d-none d-md-block">

                <a class="mb-2 text-dark " :href="u.website_url" target="_blank">{{ u.website_url }}</a>

              </div>

            </div>
          </div>

        </div>

      </div>
      <p class="text-center my-3" v-else>
        {{ $t('validation.no_uni') }} <br/>
      </p>

      <!-- end col -->
    </div>
    <div class="row" v-else>
      <div v-for="n in 12" class="col-xl-3 col-sm-6 mb-4" :key="n">
        <b-card no-body class="pt-3">
          <b-col cols="3" class="m-auto">
            <b-skeleton-img no-aspect height="60px"></b-skeleton-img>
          </b-col>
          <b-card-body>
            <b-skeleton width="20%" class="m-auto"></b-skeleton>
            <b-skeleton class="my-2"></b-skeleton>
            <b-skeleton class="mb-2"></b-skeleton>
            <b-skeleton width="75%" class="mb-2 ml-2"></b-skeleton>
          </b-card-body>
        </b-card>
      </div>

    </div>

    <!-- end row -->

    <!-- end row -->
  </div>
</template>
<style scoped>
.iq-media-group .iq-media:hover {
  z-index: 9;
}

.iq-media:hover {
  text-decoration: none;
}

.iq-media-group .iq-media {
  margin-left: -15px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-media {
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}


</style>